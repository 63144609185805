import React from "react";
import translate from '../../../i18n/translate';
const section = props => {
  const sectionTitle = props.title ? <h1 className="title">{translate(props.title ? props.title: "")}</h1> : null; 
  return (

      <section className="section" id={props.id}>
        <div className="container">
          {sectionTitle}
          {props.children}
        </div>
      </section>

  );
};

export default section;
