import React from "react";
import NavigationOption from "../NavgationOption/NavigationOption";
import NavigationIcon from '../NavigationIcon/NavigationIcon';
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import translate from '../../../i18n/translate';
import { links } from '../../../shared/links';
const navigationOptions = props => (
  <div className={`navbar-menu ${props.active ? 'is-active' : ''}`}>
    <NavigationOption to="section1" exact>
      {translate('about')}
    </NavigationOption>
    <NavigationOption to="section2" exact>
      {translate('skills')}
    </NavigationOption>
    <NavigationOption to="section3" exact>
      {translate('reachOut')}
    </NavigationOption>
    <div className="navbar-end">
      <div className="navbar-item">
        <div className="field is-grouped">
          <NavigationIcon link={links.linkedin} icon={faLinkedin}/>
          <NavigationIcon link={links.github} icon={faGithub}/>
          <NavigationIcon link={links.indeed} icon={faPaperPlane}/>
        </div>
      </div>
    </div>
  </div>
);

export default navigationOptions;
