import React from 'react';
import translate from '../../../i18n/translate';
const button = props => {
    return(
        <button className={props.buttonClass} disabled={props.disabledInd}>
            {translate(props.buttonTitle)}
        </button>
    )
    
}
export default button;