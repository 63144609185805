import React from "react";
import Section from "../../Components/UI/Section/Section";
import Tile from "../../Components/Tile/Tile";
import Article from "../../Components/UI/Article/Article";

const skills = props => {
  const tileFormat = (
    <Tile tileClass="tile is-ancestor">
      <Tile tileClass="tile is-parent">
        <Article articleClass="tile is-child notification is-success"
            articleTitle="currentWork"
            articleSubtitle="checkWritersPayroll"
            articleContent="currentWorkSummary"
          />
      </Tile>

      <Tile tileClass="tile is-vertical is-8">
        <Tile tileClass="tile is-parent">
          <Article
            articleClass="tile is-child notification is-danger"
            articleTitle="education"
            articleSubtitle="educationSubtitle"
            articleContent="educationSummary"
          />
        </Tile>

        <Tile tileClass="tile">
          <Tile tileClass="tile is-parent is-vertical">
            <Article
              articleClass="tile is-child notification is-warning"
              articleTitle="technicalSkillsTools"
              articleSubtitle="anAlwaysGrowingToolbox"
              articleContent="technicalSkillsList"
            />
            <Article
              articleClass="tile is-child notification is-primary"
              articleTitle="techIWantToLearn"
              articleSubtitle="comingSoon"
              articleContent="techIWantToLearnSummary"
            />
          </Tile>
        </Tile>
      </Tile>
    </Tile>
  );

  return (
    <Section title="whatDoYouDo" id={props.id}>
      {tileFormat}
    </Section>
  );
};

export default skills;
