import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavigationIcon = (props) => {
  return (
    <p className="control">
      <a className="bd-tw-button button" target="_blank" href={props.link} rel="noopener noreferrer">
        <span className="icon">
          <FontAwesomeIcon size="2x" icon={props.icon} />
        </span>
      </a>
    </p>
  );
};

export default NavigationIcon;
