import {LOCALES} from '../locales'
export default {
    [LOCALES.ENGLISH]: {
        'about':"About",
        'ageLocation':"26, Western MA",
        'anAlwaysGrowingToolbox':"An always-growing toolbox",
        'bio':"Hi, i'm Mike! I'm an agile Front-End developer living in Western Massachusetts. When I'm not developing, you can usually find me with my French Bulldog, Spud, or watching the latest and greatest in film.",
        'bioName':"Mike Corbett",
        'checkWritersPayroll':"CheckWriters Payroll 2016 - Present",
        'codeBeautifully':"Code Beautifully 🤘🏼",
        'comingSoon':"Coming soon...",
        'contactFormHeader':"Have a question? Suggestion? Comment? Leave me a message! ✏️",
        'currentWork':"Current Work",
        'currentWorkSummary':"I started my work at CheckWriters almost four years ago with tasks like bug-fixing and production support. Now, my role as a front-end Senior Developer in an agile development environment allows me to pass knowledge and tools to new developers. I take great pride in helping to grow our team and maintain the flow of information throughout the company.",
        'designSection':"Design Section",
        'education':"Education",
        'educationSubtitle':"Westfield State University 2012-2016",
        'educationSummary':"Bachelor of Computer Information Systems in Computer Information Science. Minor in Business Manegement. Continuing education soon with classes in UI/UX design.",
        'emailAddress':"Email Address",
        'emailAddressIsRequired':"Valid Email Address is Required",
        'footerMessage': "This project was made with React, Lottie Files and the fabulous CSS library",
        'message':"Message",
        'messageIsRequired':"Message is Required",
        'messageSuccessful':"Thanks! Your message was sent sucessfully. 👍",
        'name':"Name",
        'nameIsRequired':"Name is Required",
        'projectsSection':"Projects Section",
        'ralphJohnsonQuote':" \"Before software can be reusable it first has to be usable.\" -Ralph Johnson",
        'reachOut':"Reach Out",
        'skills':"Skills",
        'submit':"Submit",
        'techIWantToLearn':"Tech I Want to Learn",
        'techIWantToLearnSummary':"Adobe XD, Gatsby, Figma, Formik, NextJS, React Native, Svelte",
        'technicalSkillsTools':"Technical Skills/Tools",
        'technicalSkillsList':"AngularJS, Angular 8/9, Bulma CSS Library, C#, Git, HTML, JIRA, Javascript, NPOCO, React, Redux, SQL, SSMS, Typescript, Visual Studio 2017, VSCode",
        'upcomingFeatures':"Upcoming Features",
        'whatDoYouDo':"What do you do?",
        'whoAreYou':"Who Are You?",
    }
}