import React from 'react';
import { Link } from "react-scroll";
import '../../../App.sass';

const navigationOption = ( props ) => (
    <div className="navbar-item">
        <Link 
            to={props.to}
            className={props.activeClass}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>{props.children}</Link>
    </div>
);

export default navigationOption;