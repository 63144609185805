import React, { Component } from "react";
import "./App.sass";
import Layout from "../src/Components/Layout/Layout";
import {About, Contact, Footer, Landing, Skills} from './Containers/index';
import Divider from './Components/UI/Divider/Divider';
import {I18nProvider,LOCALES} from './i18n';
class App extends Component {
  render() { 
    return (
      <I18nProvider locale={LOCALES.ENGLISH}>
        <Layout>
          <Landing/>
          <About id="section1"/>
          <Divider/>
          <Skills id="section2"/>
          <Divider/>
          <Contact id="section3"/>
          <Footer/>
        </Layout>
      </I18nProvider>
    );
  }
}

export default App;
