import React from "react";
import FadeIn from 'react-fade-in';
import translate from '../../i18n/translate';

const Hero = props => {
  return (
    <section className={props.heroClass} id={props.id}>
      <div className="hero-body">
        <div className="container">
          <FadeIn delay="700">
            <h1 className="title">{translate(props.heroTitle)}</h1>
            <h2 className="subtitle">{translate(props.heroSubtitle)}</h2>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}

export default Hero;
