import React from 'react';
import Hero from '../../Components/Hero/Hero';

const landing = (props) => {
    return (
        <Hero
            heroClass = "hero is-info is-bold is-fullheight"
            heroTitle="codeBeautifully"
            heroSubtitle="ralphJohnsonQuote"/>
    );
}

export default landing;