import React from 'react';

const NavigationBurger = (props) => {
  console.log(props.active);
  return (
    <button
      onClick={props.toggleMenu}
      className={`button navbar-burger ${props.active ? 'is-active' : ''}`}>
      <span />
      <span />
      <span />
    </button>
  );
}

export default NavigationBurger;