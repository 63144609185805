//No server, so just stored in a const to keep contact component clean.
export const ContactForm = {
  name: {
    label: "name",
    elementType: "input",
    elementClass: "input is-medium",
    helperMessage: "nameIsRequired",
    elementConfig: {
      type: "text"
    },
    value: "",
    validation: {
      required: true,
      isEmail: false
    },
    valid: false,
    touched: false
  },
  email: {
    label: "emailAddress",
    elementType: "input",
    elementClass: "input is-medium",
    helperMessage: "emailAddressIsRequired",
    elementConfig: {
      type: "email"
    },
    value: "",
    validation: {
      required: true,
      isEmail: true
    },
    valid: false,
    touched: false
  },
  message: {
    label: "message",
    elementType: "textarea",
    elementClass: "textarea is-medium",
    helperMessage: "messageIsRequired",
    elementConfig: {
      type: "text",
      maxLength: 500
    },
    value: "",
    validation: {
      required: true,
      isEmail: false
    },
    valid: false,
    touched: false
  }
};
