import React from 'react';

const image = props => {
    return (
        <figure className={props.figureClass}>
            <img className={props.imageClass} src={props.src} alt={props.alt} />
        </figure>
        
    )
        
    
}

export default image;