import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import translate from "../../../i18n/translate";
import * as successImage from "../../../assets/lottie/success.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successImage.default,
  rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
};

const success  = () => {
    return (
      <div className="content has-text-centered">
        <FadeIn delay="500">
          <Lottie options={defaultOptions} height={300} width={300} />
          <h1>{translate("messageSuccessful")}</h1>
        </FadeIn>
      </div>
    );
}

export default success;