import React from 'react';
import translate from '../../../i18n/translate';
const input = ( props ) => {
    let inputElement = null;
    let helper = null;
    
    const inputClasses = [props.elementClass];

    const inputMap = new Map([
        ['input', <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed} />],
        ['textarea', <textarea className={inputClasses.join(' ')}{...props.elementConfig}value={props.value}onChange={props.changed} />],
        ['default', <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed} />]
    ]);

    inputElement = inputMap.get(props.elementType);

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push('is-danger');
        helper = <p className="help is-danger">{translate(props.helperMessage)}</p>
    }

    if (!props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push('is-success');
    }

    return (
        <div className="field">
            <label className="label">{translate(props.label)} { props.elementConfig.maxLength? props.value.length+'/'+props.elementConfig.maxLength : null}</label>
            { inputElement }
            { helper }
        </div>
        
    );

};

export default input;