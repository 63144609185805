import React from 'react';

const tile = props => {
    return (
        <div className = {props.tileClass}>
            {props.children}
        </div>

    );
}
export default tile;