import React from "react";
import Card from "../../Components/Card/Card";
import Image from "../../Components/UI/Image/Image";
import cardImage from "../../assets/Images/card-image.png";
import Section from "../../Components/UI/Section/Section";

const about = (props) => {

  const cardMedia = (
    <Image
      src={cardImage}
      alt="spudAndI"
      figureClass="image is-128x128"
      imageClass="is-rounded"
    />
  );
  
  return (
    <Section title="whoAreYou" id={props.id}>
      <Card
        cardImage={cardMedia}
        cardTitle="bioName"
        cardSubtitle="ageLocation"
        cardContent="bio"
      />
    </Section>
  );
};

export default about;
