import React from "react";
import translate from "../../i18n/translate";
import bulmaLogo from "../../assets/Images/bulma-logo.png";
const footer = () => {
  const image = (
    <a href="https://bulma.io/made-with-bulma/" target="_blank" rel="noopener noreferrer">
        <img src={bulmaLogo} alt="BULMA.IO "width="64"height="64"/>
    </a>
  );

  return (
    <footer className="footer">
      <div className="content- has-text-centered">
        <p className="has-text">{translate("footerMessage")} {image}</p>
        <br/>
        <p className="has-text">{translate("upcomingFeatures")}</p>
        <br/>
        <p className="has-text">{translate("designSection")}</p>
        <p className="has-text">{translate("projectsSection")}</p>
      </div>
    </footer>
  );
};

export default footer;
