import React, { Component } from "react";
import Section from "../../Components/UI/Section/Section";
import Button from "../../Components/UI/Button/Button";
import Input from "../../Components/UI/Input/Input";
import { ContactForm } from './Contact-Form.js';
import { checkValidity, updateObject } from "../../shared/utility.js";
import { instance, messagesURL } from '../../environments/axios-instance';
import Success from '../../Components/UI/Success/success';
import translate from '../../i18n/translate';
class Contact extends Component {

  state = {
    contactForm: {...ContactForm},
    formIsValid: false,
    formIsSubmitted: false
  };

  inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(
      this.state.contactForm[inputIdentifier],
      {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.contactForm[inputIdentifier].validation
        ),
        touched: true
      }
    );
    const updatedForm = updateObject(this.state.contactForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ contactForm: updatedForm, formIsValid: formIsValid });
  };

  messageHandler = (event) => {
    event.preventDefault();
    const saveItem = {};
    for (let formElement in this.state.contactForm){
      saveItem[formElement] = this.state.contactForm[formElement].value;
    }
    instance.post( messagesURL, saveItem )
    .then( response => {
        this.setState({formIsSubmitted: true})
    })
    .catch( error => {
        //some error handling
    });
  }
  render() {
    const formElementsArray = [];
    for (let key in this.state.contactForm) {
      formElementsArray.push({
        id: key,
        config: this.state.contactForm[key]
      });
    }

    let buttonClass = ['button is-medium'];
    if(this.state.formIsValid) {
      buttonClass.push('is-success')
    }
    let form = (
      <form onSubmit={this.messageHandler}>
        <p>{translate("contactFormHeader")}</p>
        {formElementsArray.map(formElement => (
            <Input
              key={formElement.id}
              label={formElement.config.label}
              helperMessage = {formElement.config.helperMessage}
              elementClass = {formElement.config.elementClass}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
            />
        ))}
        <div className="content has-text-centered">
          <Button
            buttonClass={buttonClass.join(' ')}
            buttonTitle="submit"
            disabledInd = {this.state.formIsValid ? false : true}
          />
        </div>
      </form>
    );
    return (
      <Section title='reachOut' id={this.props.id}>
        <div className="box">
          <div className="content">
            {this.state.formIsSubmitted ? <Success/> : form}
          </div>
        </div>
      </Section>
    );
  }
}

export default Contact;
