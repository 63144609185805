import React from "react";
import translate from '../../i18n/translate';
const card = props => {
  return (
    <div className="card has-background-primary">
      <div className="card-content">
        <div className="media">
          <div className="media-left">{props.cardImage}</div>
          <div className="media-content">
            <p className="title is-4">{translate(props.cardTitle)}</p>
            <p className="subtitle is-6">{translate(props.cardSubtitle)}</p>
          </div>
        </div>
        <div className="content">
          <p>{translate(props.cardContent)}</p>
        </div>
      </div>
    </div>
  );
};
export default card;
