import React from 'react';
import translate from '../../../i18n/translate';
const article = (props) => {
    return (
        <article className = {props.articleClass}>
            <p className="title">{translate(props.articleTitle)}</p>
            <p className="subtitle">{translate(props.articleSubtitle)}</p>
            <div className="content">{translate(props.articleContent)}</div>
        </article>
    );
}

export default article;