import React, { Component } from "react";
import "./NavigationBar.css";
import NavigationBurger from "../NavigationBurger/NavigationBurger";
import NavigationOptions from "../NavigationOptions/NavigationOptions";

class navigationBar extends Component {

  state = {
    activeMenu: false
  };
  
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu
    });
  };

  render() {

    return (
      <nav className="navbar is-fixed-top">
        <div className="navbar-brand">
          <NavigationBurger 
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}/>
        </div>
        <NavigationOptions active={this.state.activeMenu}/>
      </nav>
    );
  }
}

export default navigationBar;
